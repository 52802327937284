import React, {useState} from 'react';
import TickerSearch from './tickerSearch';
import fetchStockData from './hcc-dao';
import LoginPage from "./LoginPage";
import './App.css';
import {keys} from "./secrets/keys";

const ALPHAVANTAGE_API_KEY = keys.ALPHAVANTAGE_API_KEY; // Replace with your actual API key

function App() {
    const [currentTickerTyped, setCurrentTickerTyped] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Add isLoggedIn state

    const handleLogin = () => {
        setIsLoggedIn(true);
    };
    const handleSearch = async (ticker) => {
        try {
            const data = await fetchStockData(ticker, ALPHAVANTAGE_API_KEY);
            if (data["Error Message"]) alert('invalid api call: either cannot find stock or call has failed')
            jsonToCsvAndDownload(data);
        } catch (error) {
            console.error(error);
        }
    };

    function jsonToCsvAndDownload(data) {
        const rows = [];
        const headers = ["Date", "Open", "High", "Low", "Close", "Volume"];
        rows.push(headers.join(","));

        const timeSeries = data["Time Series (Daily)"];
        const dates = Object.keys(timeSeries).sort((a, b) => new Date(b) - new Date(a));

        dates.forEach(date => {
            const dailyData = timeSeries[date];
            const formattedDate = formatDate(date);
            const row = [
                formattedDate,
                toCurrency(dailyData["1. open"]),
                toCurrency(dailyData["2. high"]),
                toCurrency(dailyData["3. low"]),
                toCurrency(dailyData["4. close"]),
                dailyData["6. volume"]
            ];
            rows.push(row.join(","));
        });


        const csvContent = rows.join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        const firstDate = formatDate(dates[0]);
        const fileName = `${currentTickerTyped}-${firstDate}.csv`;
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    }

    function toCurrency(value) {
        return `$${parseFloat(value).toFixed(2)}`;
    }

    if (!isLoggedIn) {
        return <LoginPage onLogin={handleLogin}/>;
    }

    return (<div className="App" style={{paddingLeft: '20px'}}>
            <div className="app-wrapper">
                <h1>HCC Export as CSV</h1>
                <TickerSearch onSearch={handleSearch} currentTickerTyped={currentTickerTyped}
                              setCurrentTickerTyped={setCurrentTickerTyped}/>
            </div>
        </div>);
}

export default App;